const initAccordion = () => {
    const accordions = document.querySelectorAll('.js-wrock-accordion');
    accordions &&
        accordions.forEach((item) => {
            item.addEventListener('click', (event) => {
                const target = event.target;
                const btn = target.closest('.js-wrock-accordion__btn');
                if (!btn)
                    return;
                const element = btn.parentElement;
                const content = element.querySelector('.js-wrock-accordion__content');
                const openItem = item.querySelector('.js-wrock-accordion__item.open');
                element.classList.toggle('open');
            });
        });
};
export const initInnerAccordion = () => {
    const innerAccordions = document.querySelectorAll('.js-inner-accordion');
    innerAccordions &&
        innerAccordions.forEach((item) => {
            item.addEventListener('click', (event) => {
                const target = event.target;
                const btn = target.closest('.js-inner-accordion__btn');
                if (!btn)
                    return;
                const element = btn.parentElement;
                const content = element.querySelector('.js-inner-accordion__content');
                const openItem = item.querySelector('.js-inner-accordion__item.open');
                element.classList.toggle('open');
            });
        });
};
export default initAccordion;
